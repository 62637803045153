import React, {useState} from "react";
import { Tabs, Tab } from "react-bootstrap";
import "../filters/filters.css";

const Filters = ({
  dataTableOriginal,
  optionList,
  setDataTable,
  setOptionList,
  mobile,
}) => {
  const tabs = [
    ["Moneda", "Pesos", "Dólares"],
    [
      "Tipo de Fondo",
      "Transaccional",
      "Renta Fija",
      "Renta Mixta",
      "Renta Variable",
    ],
    ["Perfil Inversor", "Conservador", "Moderado", "Audaz"],
  ];

  const [filterSelected, setFilterSelected] = useState([]);

  const addFilter = (element) => {
    let option = element.target;
    let filters = optionList;
    //console.log('option', option)
    if (filters.length === 0) {
      filters.push(cleanStr(option.innerText));
      setOptionList(filters);
    } else if (!filters.includes(cleanStr(option.innerText))) {
      filters.push(cleanStr(option.innerText));
      setOptionList(filters);
    }
    // console.log('filters',filters)
    const match = [];

    const filtered = dataTableOriginal.filter(
        element => {
          element.matchesFilter = 0;
          filters.forEach((filter) => {
            if (
                element.TipoFondo?.indexOf(filter) !== -1 ||
                element.PerfilInversor?.indexOf(filter) !== -1 ||
                element.moneda?.indexOf(filter) !== -1
            )
              element.matchesFilter++
            })
          return (
              element.matchesFilter === filters.length
          )
        }
    )
    // console.log('filtered',filtered)
    setFilterSelected(filtered);

    dataTableOriginal.forEach((element) => {
      let currentElement = element;
      //console.log(element)
      let tipoFondo = element.TipoFondo;
      let perfil = element.PerfilInversor;
      let moneda = element.moneda;

      filters.forEach((filter) => {
        //console.log('ii',tipoFondo?.indexOf(filter))
        if (
          tipoFondo?.indexOf(filter) !== -1 ||
          perfil?.indexOf(filter) !== -1 ||
          moneda?.indexOf(filter) !== -1
            /* filter === "Pesos" ||
             filter === "Dolares"*/
        ) {
          if (match.length === 0) {
            match.push(currentElement);
          } else if (match.indexOf(currentElement) === -1) {
            match.push(currentElement);
          }
        }
      });
    });
    /*setDataTable(match);*/
    setDataTable(filtered);
  };

  const deleteFilter = (event) => {
    let filters = optionList;
    let toDelete = cleanStr(event.target.innerText);
    const finalFilters = filters.filter((value) => {
      return value !== toDelete;
    });

    setOptionList(finalFilters);
    if (finalFilters.length > 0) {

      const filtered2 = dataTableOriginal.filter(
          element => {
            element.matchesFilter = 0;
            // console.log('finalFilters',finalFilters)
            finalFilters.forEach((filter) => {
              if (
                  element.TipoFondo?.indexOf(filter) !== -1 ||
                  element.PerfilInversor?.indexOf(filter) !== -1 ||
                  element.moneda?.indexOf(filter) !== -1
              )
                element.matchesFilter++;

            })
            // console.log(element.matchesFilter)
            return (
                element.matchesFilter === finalFilters.length
            )
          }
      )
      // console.log('filtered2',filtered2)
      setFilterSelected(filtered2);

      const filteredDataTable = dataTableOriginal.filter((element) => {
        return (
          finalFilters.includes(element.TipoFondo) ||
          finalFilters.includes(element.PerfilInversor)
        );
      });
      /*setDataTable(filteredDataTable);*/
      setDataTable(filtered2);
    } else {
      setDataTable(dataTableOriginal);
    }
  };

  const displayFilter = (e) => {
    //console.log('e displayFilter',e)
    const content = e.target.nextElementSibling;
    if (content.style.maxHeight) {
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = content.scrollHeight + "px";
    }
  };

/*  useEffect(() => {
    const filterIcon = document.querySelector(".filter_icon");

    if (filterIcon) {
      filterIcon.addEventListener("click", displayFilter);
    }

    return () => {
      if (filterIcon) {
        filterIcon.removeEventListener("click", displayFilter);
      }
    };
  }, []);*/

  const cleanStr = (value)=> {
    const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
    return value.split('').map( letra => acentos[letra] || letra).join('').toString();
  }

  return (
    <>
      {mobile > 992 && (
        <Tabs
          defaultActiveKey="1"
          transition={true}
          id="tabs_rentabilidad"
          className="mb-3"
        >
          {tabs.map((tab, key) => {
            return (
              <Tab key={key} eventKey={String(key + 1)} title={tab[0]}>
                <div>
                  <ul className="tabs_rentabilidad">
                    {tab.map((listItem, key) => {
                      return (
                        key > 0 && (
                          <li key={key}>
                            <button data-filter={tab[0]}
                              onClick={(event) => {
                                addFilter(event);
                              }}
                            >
                              {listItem}
                            </button>
                          </li>
                        )
                      );
                    })}
                  </ul>
                </div>
              </Tab>
            );
          })}
        </Tabs>
      )}

      {mobile < 992 && (
        <>
          <button type="button" className="filter_icon" onClick = {displayFilter}>
          </button>
          <div className="filter_container filter_content">
            {tabs.map((element, key) => {
              return (
                <div key={key} className="filter_items">
                  <label htmlFor={element[0]}>{element[0]}:</label>
                  {/*
                    <select defaultValue={element[0]}>
                    {element.map((val, key) => {
                      return (
                        <option
                          disabled={key === 0 ? true : false}
                          value={val}
                          key={key}
                        >
                          {val}
                        </option>
                      );
                    })}
                  </select>*/}
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      textAlign: "center",
                    }}
                  >
                    {element.map((word, key) => {
                      return (
                        key > 0 && (
                          <button
                            key={key} data-filter={element[0]}
                            onClick={(e) => {
                              addFilter(e);
                            }}
                            className="filter_item"
                          >
                            {word}
                          </button>
                        )
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}

      {optionList.length > 0 && (
        <ul className="filters_rentabilidad" style={{ display: "flex" }}>
          {optionList.map((ele, key) => {
            return (
              <li className="filter_tags" key={key}>
                <button
                  className="filter_buttons"
                  onClick={(event) => {
                    deleteFilter(event);
                  }}
                >
                  {ele}
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default Filters;
